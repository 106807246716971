<template>
    <div>
        <div class="main-title">直播体验课</div>
        <div class="content-list-page page-content">
            <div class="list-head-box">
                <div data-v-69874ad0="" class="list-head" >
                    <p data-v-69874ad0="" class="list-head-title">温馨提示</p>
                    <ul data-v-69874ad0="">
                        <li data-v-69874ad0="">创建课程后会自动同步到class in后台</li>
                    </ul>
                </div>
            </div>


            <div class="padding-t-sm padding-b-sm">
                <span class="fl-l" >
                    <el-input clearable placeholder="请输入课程名称" v-model="searchName" />
                </span>


                <span class="margin-l-sm fl-l">
                    <el-button type="primary" icon="el-icon-search" size="small" @click="search">搜索</el-button>
                </span>

                <span class=" fl-r">
                    <el-button type="primary" size="small" @click="$router.push('/addLiveTrial')" >新建直播体验课</el-button>
                </span>

                <div style="clear: both"></div>

            </div>


            <div class="table-content">

                <el-table
                        :header-cell-style="{padding:0}"
                        ref="singleTable"
                        :data="tableData"
                        highlight-current-row
                        v-loading="loading"
                        style="width: 100%;background: #fff;">
                    <el-table-column
                            label="ID"
                            property="id"
                            width="80">
                    </el-table-column>
                    <el-table-column
                            label="课程名称" >
                        <template slot-scope="scope">
                            <span>{{scope.row.name}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            label="封面"  >
                        <template slot-scope="scope">
                            <el-image
                                    style="width: 100px; height: 60px"
                                    :preview-src-list="[imageUrl + scope.row.pic]"
                                    :src="imageUrl + scope.row.pic"
                                    fit="cover"></el-image>
                        </template>
                    </el-table-column>
                    <el-table-column
                            property="index"
                            width="80"
                            label="价格">
                        <template slot-scope="scope">
                            <span>￥{{scope.row.price}}</span>
                        </template>
                    </el-table-column>
                  <el-table-column
                      property="index"
                      width="80"
                      label="是否免费">
                    <template slot-scope="scope">
                      <span>{{scope.row.free == 1 ? '免费' : '收费'}}</span>
                    </template>
                  </el-table-column>
                    <el-table-column
                            property="created_at"
                            label="创建时间" >
                        <template slot-scope="scope">
                            <span>{{scope.row.created_at}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            property="created_at"
                            label="报名时间" >
                        <template slot-scope="scope">
                            <span>{{scope.row.start_at}}</span></br>
                            <span>{{scope.row.end_at}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="是否上架">
                        <template slot-scope="scope">
                            <el-switch
                                    :active-value="1"
                                    :inactive-value="0"
                                    v-model="scope.row.status"
                                    @change="switchStatus(scope.row)"
                            />
                        </template>
                    </el-table-column>
                    <el-table-column
                            property="address"
                            label="操作">
                        <template slot-scope="scope">
                            <span>

                              <el-link type="primary" @click="share(scope.row)">分享二维码</el-link>
                                -
                                <el-link type="primary" @click="$router.push('/addLiveTrial?id='+scope.row.id)">编辑</el-link>
                                -
                                <el-button
                                        size="mini"
                                        type="text"
                                        @click="
									$router.push({
										path: '/liveTrialDetail/'+scope.row.id,
									})
								">详情</el-button>
                            </span>
                        </template>
                    </el-table-column>
                </el-table>

            </div>

            <div class="table-batch" >
                <span class="fl-r" >
                    <el-pagination
                            small
                            background
                            :current-page="page"
                            :page-sizes="[10, 20]"
                            :page-size="pageSize"
                            layout="total, sizes, prev, pager, next, jumper"
                            @size-change="setPageSize"
                            @current-change="setPage"
                            :total="total">
                </el-pagination>
                </span>
                <div style="clear: both"></div>
            </div>
        </div>

      <el-dialog
          title="分享二维码"
          :visible="isShowCode"
          append-to-body
          :show-close="false"
          width="30%"
          >
        <div style="padding: 20px">
          <make-share-pic
              :height="200"
              :url="codeUrl"
              :width="200"
          ></make-share-pic>
        </div>
        <span slot="footer" >
          <el-button @click="isShowCode = false">取 消</el-button>
          <el-button type="primary" @click="isShowCode = false">确 定</el-button>
        </span>
      </el-dialog>

    </div>
</template>

<script>
    import {mapActions} from "vuex";
    import SelectImages from "../../../components/selectImages/index";
    import MakeSharePic from "@/components/makeSharePic/index.vue";

    export default {
        components: { MakeSharePic },
        name: "template-list",
        data(){
            return {
                rules: {
                    name: [
                        { required: true, message: '请输入课程名称', trigger: 'blur' },
                    ],
                    pic: [
                        { required: true, message: '请选择图片', trigger: 'blur' },
                    ]
                },
                image:'',
                codeUrl:'',
                isShowCode:false,
                searchName:'',
                dialogVisible:false,
                page:1,
                pageSize:10,
                total:0,
                loading:false,
                imageUrl:config.imageUrl,
                value: '',
                pickerOptions: {
                    shortcuts: [{
                        text: '最近一周',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近一个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近三个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                            picker.$emit('pick', [start, end]);
                        }
                    }]
                },
                value2: '',
                tableData: [],
                teachers: [],
                form:{
                    id:'',
                    name:'',
                    pic:'',
                    teacherUid:'',
                },
                submitLoading:false,
            }
        },
        methods:{
            ...mapActions("live", ["getLiveTrialList"]),
            ...mapActions("punch", [
                "switchDetailStatus",
            ]),
            async switchStatus({ status, id }) {
                this.loading = true;

                await this.switchDetailStatus({
                    model: "LiveCourse\\LiveTrialModel",
                    id,
                    status,
                });

                this.loading = false;
            },
            search(){
                this.tableData = []
                this.page = 1
                this.getList()
            },
            getList(){
                this.loading = true
                let data = {
                    name:this.searchName,
                    page:this.page,
                    pageSize:this.pageSize
                }
                this.getLiveTrialList(data).then(res => {
                    this.tableData = res.data.list
                    this.total = res.data.total
                }).finally(() =>{
                    this.loading = false
                })

            },
            setPageSize(pageSize){
                this.pageSize = pageSize
                this.getList()
            },
            setPage(page){
                this.page  = page
                this.getList()
            },
          share(row){
            this.codeUrl = 'http://h5.youfantx.com/pages/login/index?page='+encodeURIComponent('/pages/promotionPage/trialCourse?id='+row.id);
            this.isShowCode = true;
            console.log(this.codeUrl)
          }
        },
        mounted() {
            this.getList()
        }
    }
</script>

<style>

</style>
